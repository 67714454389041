import useLazyRef from 'hook/useLazyRef'
import { IsServer } from 'util/browser'

export const useLoadObserver = () => {
  const observerControl = useLazyRef(() => (IsServer() ? null : getObserver()))

  return observerControl
}

let observer: IntersectionObserver | null = null
const getObserver = () => {
  if (!observer) {
    observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            console.log('video is loading')
            observer.unobserve(entry.target)
            load(entry.target)
          }
        })
      },
      {
        rootMargin: '0px 300% 300% 0px',
        threshold: 0.01
      }
    )
  }
  return observer
}

const isVideo = (element: Element): element is HTMLVideoElement => {
  return element.nodeName.toLowerCase() === 'video'
}
const isSource = (element: HTMLElement): element is HTMLSourceElement => {
  return element.nodeName.toLowerCase() === 'source'
}
function load(element: Element) {
  if (!isVideo(element)) {
    return
  }

  if (element.getAttribute('data-src')) {
    element.src = element.getAttribute('data-src') || ''
  } else {
    const allSource = element.querySelectorAll('source')
    if (allSource && allSource.length > 0) {
      allSource.forEach(source => {
        if (isSource(source)) {
          source.setAttribute('src', source.getAttribute('data-src') || '')
        }
      })
    }
  }

  element.load()
}
