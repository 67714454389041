'use client'

import * as React from 'react'

import { Image } from 'component/ui/image'
import cn from 'util/cn'
import { useLoadObserver } from './use-load-observer'

export interface IVideoPlayer extends React.ComponentPropsWithoutRef<'video'> {
  src: string
  loading?: 'lazy' | 'eager'
  errorVideoPoster?: string
  playerRef?:
    | React.MutableRefObject<HTMLVideoElement | null>
    | ((el: HTMLVideoElement | null) => void)
}

const VideoPlayer = (props: IVideoPlayer) => {
  const {
    playerRef,
    src,
    errorVideoPoster,
    loading = 'eager',
    ...restProps
  } = props
  const [error, dispatchError] = React.useReducer(() => true, false)
  const [loaded, dispatchLoaded] = React.useReducer(() => true, false)
  const cleanupRef = React.useRef<(() => void)[]>([])

  const observerControl = useLoadObserver()

  const videoCallbackRef = React.useCallback(
    (el: HTMLVideoElement | null) => {
      // export video element
      if (playerRef) {
        if (typeof playerRef === 'function') {
          playerRef(el)
        } else {
          playerRef.current = el
        }
      }

      cleanupRef.current.forEach(cleanup => cleanup())

      if (!el) {
        return
      }

      function initVideo(video: HTMLVideoElement) {
        video.addEventListener('error', dispatchError, { once: true })
        video.addEventListener('loadeddata', dispatchLoaded, { once: true })
        cleanupRef.current.push(() =>
          video.removeEventListener('error', dispatchError)
        )
        cleanupRef.current.push(() =>
          video.removeEventListener('loadeddata', dispatchLoaded)
        )
      }

      if (loading === 'eager') {
        initVideo(el)
        return
      }

      observerControl.current?.observe(el)
      cleanupRef.current.push(() => observerControl.current?.unobserve(el))
      initVideo(el)
    },
    [playerRef, loading, observerControl]
  )

  const sourceProps = {
    [loading === 'lazy' ? 'data-src' : 'src']: src
  }

  if (loaded && error) {
    console.error('VideoPlayer: failed to load video', src)
    return (
      errorVideoPoster && (
        <Image src={errorVideoPoster} alt='playsee spot' fill={true} />
      )
    )
  }

  return (
    <video
      ref={videoCallbackRef}
      {...restProps}
      className={cn(
        'h-full w-full min-w-full object-cover',
        restProps.className
      )}
    >
      <source {...sourceProps} type='video/mp4' />
      Your browser does not support the video tag.
    </video>
  )
}

export default VideoPlayer
