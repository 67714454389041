import { createTokenClass, registerPlugin, type Plugin } from 'linkifyjs'

const MentionToken = createTokenClass('mention', {
  isLink: true,
  toHref() {
    return '/' + this.toString().slice(1)
  }
})

/**
 * Mention parser plugin for linkify
 */
export const mention: Plugin = ({ scanner, parser }) => {
  const { HYPHEN, UNDERSCORE, AT, DOT } = scanner.tokens
  const { domain } = scanner.tokens.groups

  // @
  const At = parser.start.tt(AT)

  // Begin with hyphen (not mention unless contains other characters)
  const AtHyphen = At.tt(HYPHEN)
  AtHyphen.tt(HYPHEN, AtHyphen)

  // Valid mention (not made up entirely of symbols)
  // @ts-expect-error
  const Mention = At.tt(UNDERSCORE, MentionToken)

  At.ta(domain, Mention)
  AtHyphen.tt(UNDERSCORE, Mention)
  AtHyphen.ta(domain, Mention)

  // More valid mentions
  Mention.ta(domain, Mention)
  Mention.tt(HYPHEN, Mention)
  Mention.tt(UNDERSCORE, Mention)
  Mention.tt(DOT, Mention)
}

registerPlugin('mention', mention)
