'use client'

import * as AvatarPrimitive from '@radix-ui/react-avatar'

import { twc, type TwcComponentWithAsChild } from 'util/cn'

type AvatarProps = TwcComponentWithAsChild<typeof AvatarPrimitive.Root>
const Avatar = twc(AvatarPrimitive.Root).attrs<AvatarProps>(props => ({
  asChild: props.$asChild
}))`relative flex h-[48px] w-[48px] shrink-0 overflow-hidden rounded-full`
Avatar.displayName = `twc(${AvatarPrimitive.Root.displayName}))`

type AvatarImageProps = TwcComponentWithAsChild<typeof AvatarPrimitive.Image>
const AvatarImage = twc(AvatarPrimitive.Image).attrs<AvatarImageProps>(
  props => ({
    asChild: props.$asChild
  })
)`h-full w-full object-cover`
AvatarImage.displayName = `twc(${AvatarPrimitive.Image.displayName}))`

type AvatarFallbackProps = TwcComponentWithAsChild<
  typeof AvatarPrimitive.Fallback
>
const AvatarFallback = twc(AvatarPrimitive.Fallback).attrs<AvatarFallbackProps>(
  props => ({
    asChild: props.$asChild
  })
)`flex h-full w-full items-center justify-center rounded-full bg-background-2nd`
AvatarFallback.displayName = `twc(${AvatarPrimitive.Fallback.displayName}))`

export { Avatar, AvatarFallback, AvatarImage }
