import { useEffect, useState } from 'react'

import { GA_COMMON_EVENT, type GA_CATEGORY } from 'constant'
import { type ValueOf } from 'type/object'
import { SendGAEvent } from 'util/GA'
import prefixGAAction from 'util/prefixGAAction'

type GACategory = ValueOf<typeof GA_CATEGORY> | ''

/**
 * React hook to send page view to Google Analytics
 * @param category GA category
 */
export default function useGAPageView(gaCategory: GACategory) {
  const [isClient, setIsClient] = useState<boolean>(false)

  useEffect(() => {
    if (!isClient) {
      setIsClient(true)
      return
    }
    if (!gaCategory) return

    const action = prefixGAAction(GA_COMMON_EVENT.Main, gaCategory)

    SendGAEvent(gaCategory, action)
  }, [isClient, gaCategory])
}
