'use client'

import { TIME_OUT } from 'constant'
import { IsServer } from 'util/browser'

const INTERESTING_EVENTS = [
  'keypress',
  'keydown',
  'click',
  'contextmenu',
  'dblclick',
  'mousemove',
  'scroll',
  'touchmove',
  'touchstart'
]
const noop = () => {}

type IdleListener = (idle: boolean) => void

let idleListeners = new Set<IdleListener>()
let unSubscribe: (() => void) | undefined
let watchers = 0
let isIdle = false
let idleCountdown: NodeJS.Timeout | undefined
let idleTimeoutMs = TIME_OUT.FIVE_SECOND // Default is 5 seconds
let isThrottling = false
const throttleTimeoutMs = 500
let once = false

let idle = false

export const idleTimerStore = {
  startWatch() {
    if (IsServer()) return

    idle = false
    unSubscribe = watch()
  },
  stopWatch() {
    if (unSubscribe) unSubscribe()
  },
  idle() {
    idle = true
    emitVolumeChange(idle)
    if (once) {
      idleTimerStore.stopWatch()
    }
  },
  unIdle() {
    idle = false
    emitVolumeChange(idle)
  },
  onIdle(
    listener: IdleListener,
    options: { once?: boolean; timeoutMs?: number } = {}
  ) {
    if (IsServer()) return noop

    // not 0, null, undefined
    if (options.timeoutMs) {
      idleTimeoutMs = options.timeoutMs
    }

    if ('once' in options) {
      once = !!options.once
    }

    idleListeners.add(listener)
    return () => idleListeners.delete(listener)
  }
}

function emitVolumeChange(idle: boolean) {
  for (let listener of idleListeners) {
    listener(idle)
  }
}

function watch() {
  watchers++
  if (watchers > 1) return

  startCountdown()

  for (const event of INTERESTING_EVENTS) {
    document.addEventListener(event, detectAction, { passive: true })
  }

  return () => {
    watchers--

    if (watchers === 0) {
      for (const event of INTERESTING_EVENTS) {
        document.removeEventListener(event, detectAction)
      }
      clearCountdown()
    }
  }
}

function startCountdown() {
  idleCountdown = setTimeout(() => {
    idleTimerStore.idle()
  }, idleTimeoutMs)
}

function clearCountdown() {
  if (idleCountdown) clearTimeout(idleCountdown)
}

function startThrottle() {
  setTimeout(() => (isThrottling = false), throttleTimeoutMs)
}

function detectAction() {
  if (isThrottling) return
  if (isIdle) idleTimerStore.unIdle()

  isThrottling = true

  clearCountdown()
  startCountdown()
  startThrottle()
}
