export interface IIdleTimerStore {
  startWatch: () => void
  stopWatch: () => void
}

export interface IMutedStore {
  subscribe: (listener: (isMuted: boolean) => void) => () => void
  getSnapshot: () => boolean
}

export interface IPlayStateStore {
  subscribe: (listener: (playState: 'playing' | 'paused') => void) => () => void
}

export interface IUserPausedStore {
  subscribe: (listener: (isPaused: boolean) => void) => () => void
  toggle: () => void
  getSnapshot: () => boolean
}

export interface IUseViewCountStore {
  increase: () => void
}

const noop = () => {}
const defaultViewCountStore = {
  increase: noop
}
const defaultIdleTimerStore = {
  startWatch: noop,
  stopWatch: noop
}

export function createSpotControl(DI: {
  mutedStore: IMutedStore
  playStateStore: IPlayStateStore
  userPausedStore: IUserPausedStore
  idleTimerStore?: IIdleTimerStore
  useViewCountStore?: IUseViewCountStore
}) {
  const {
    mutedStore,
    playStateStore,
    userPausedStore,
    idleTimerStore = defaultIdleTimerStore,
    useViewCountStore = defaultViewCountStore
  } = DI

  let video: HTMLVideoElement | null = null
  let unSubscribe: Record<string, () => void> = {}

  async function play() {
    if (!video) {
      return
    }

    return video.play().catch(noop)
  }

  function pause() {
    if (!video) {
      return
    }

    video.pause()
  }

  function attach(videoElement: HTMLVideoElement) {
    video = videoElement
    video.muted = mutedStore.getSnapshot()
    // control muted
    unSubscribe.muted = mutedStore.subscribe(isMuted => {
      if (!video) {
        return
      }

      video.muted = isMuted
    })
  }

  function detach() {
    Object.values(unSubscribe).forEach(unSubscribe => unSubscribe())
    unSubscribe = {}
    video = null
  }

  function resume() {
    !userPausedStore.getSnapshot() && play()
  }

  function onScreen() {
    if (!userPausedStore.getSnapshot()) {
      play()
    }

    // auto playing when dropdown close
    unSubscribe.playState = playStateStore.subscribe(playState => {
      if (!video) {
        return
      }
      const isPaused = playState === 'paused'

      if (isPaused) {
        pause()
      } else {
        resume()
      }
    })
    // control user paused
    unSubscribe.userPaused = userPausedStore.subscribe(isPaused => {
      if (!video) {
        return
      }

      if (isPaused) {
        pause()
        idleTimerStore.startWatch()
      } else {
        play()
        idleTimerStore.stopWatch()
      }
    })

    useViewCountStore.increase()
  }

  function offScreen() {
    pause()

    unSubscribe.playState?.()
    unSubscribe.userPaused?.()
    delete unSubscribe.playState
    delete unSubscribe.userPaused
  }

  return {
    attach,
    detach,
    play,
    pause,
    resume,
    onScreen,
    offScreen
  }
}
