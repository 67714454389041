import { cva, type VariantProps } from 'class-variance-authority'
import { type MouseEvent, type ReactNode } from 'react'

import { Avatar, AvatarFallback, AvatarImage } from 'component/ui/avatar'
import { HeadshotSize, HeadshotType, NO_PHOTO_PATH } from 'constant'
import cn from 'util/cn'

const headShotVariant = cva(
  'relative before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:rounded-full before:border before:border-glass_special-separators',
  {
    variants: {
      size: {
        [HeadshotSize.xxs]: `h-[18px] w-[18px]`,
        [HeadshotSize.xs]: `h-[24px] w-[24px]`,
        [HeadshotSize.sm]: `h-[36px] w-[36px]`,
        [HeadshotSize.md]: `h-[48px] w-[48px]`,
        [HeadshotSize.lg]: `h-[64px] w-[64px]`,
        [HeadshotSize.xl]: `h-[96px] w-[96px]`,
        [HeadshotSize.xxl]: `h-[128px] w-[128px]`
      },
      rounded: {
        true: 'rounded-full',
        false: 'rounded-[6px]'
      }
    },
    defaultVariants: {
      size: HeadshotSize.md,
      rounded: true
    }
  }
)

type HeadShotVariants = VariantProps<typeof headShotVariant>

const AvatarVideo = ({ src }: { src: string | undefined }) => {
  return (
    <video
      className='absolute bottom-0 left-0 right-0 top-0 object-cover'
      src={src}
      autoPlay={true}
      loop={true}
      muted={true}
    />
  )
}

interface IHeadShot extends HeadShotVariants {
  type?: HeadshotType
  src?: string | undefined
  className?: string
  rounded?: boolean
  onClick?: (event: MouseEvent) => void
  fallback?: ReactNode
  gtmTag?: string
  gaAction?: string
  title?: string
}
const HeadShot = (props: IHeadShot) => {
  const {
    size,
    rounded,
    type,
    src,
    className,
    fallback,
    onClick,
    gtmTag,
    gaAction,
    title
  } = props

  return (
    <Avatar
      className={cn(headShotVariant({ size, rounded }), className)}
      onClick={onClick}
      data-gtm-tag={gtmTag}
      data-ga-click={gaAction}
      title={title}
    >
      {type === HeadshotType.Video ? (
        <AvatarVideo src={src} />
      ) : (
        <AvatarImage src={src} />
      )}
      <AvatarFallback>
        {fallback ?? (
          <img
            src={NO_PHOTO_PATH}
            className='h-full w-full object-cover'
            alt='Fallback avatar'
          />
        )}
      </AvatarFallback>
    </Avatar>
  )
}

export default HeadShot
