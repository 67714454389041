'use client'
import React from 'react'

import { type GA_CATEGORY } from 'constant'
import { idleTimerStore } from 'store/global/idle-timer'
import { usePopupStore } from 'store/global/popup'
import { useDeviceStore } from 'store/server-context/device'
import { type ValueOf } from 'type/object'
import useGAPageView from './useGAPageView'

const getSnapshot = () => ''

type GACategory = ValueOf<typeof GA_CATEGORY> | ''
interface IPageLoad {
  category: GACategory
  children: React.ReactNode
}
const PageWrapper = (props: IPageLoad) => {
  React.useSyncExternalStore(
    () => {
      const unSubs = new Set<() => void>()
      const dispose = () => {
        unSubs.forEach(unSub => unSub())
      }
      const showDownloadPopup = usePopupStore.getState().showDownloadPopup
      let device = useDeviceStore.getState().device
      device !== 'desktop' && showDownloadPopup()

      let isDownloadOpen = usePopupStore.getState().mask.download
      let isShareOpen = usePopupStore.getState().mask.share

      unSubs.add(
        usePopupStore.subscribe(state => {
          isDownloadOpen = state.mask.download
          isShareOpen = state.mask.share
        })
      )

      unSubs.add(
        idleTimerStore.onIdle(() => {
          if (device !== 'desktop' && !isDownloadOpen && !isShareOpen) {
            showDownloadPopup()
            dispose()
          }
        })
      )

      return dispose
    },
    getSnapshot,
    getSnapshot
  )

  useGAPageView(props.category)

  return <>{props.children}</>
}

export default PageWrapper
