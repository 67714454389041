import { API_V2 } from 'constant/apiUrl'
import type { Fetch } from 'lib/fetch/type'
import type { IRespV2 } from 'type/common'

// V2
export const likeCommentV2 = async (
  fetcher: Fetch,
  id: string
): Promise<IRespV2> => {
  const res = await fetcher(API_V2.CONTENT + `/comment/${id}/like`, {
    method: 'PUT'
  })

  return res
}

export const unlikeCommentV2 = async (
  fetcher: Fetch,
  id: string
): Promise<IRespV2> => {
  const res = await fetcher(API_V2.CONTENT + `/comment/${id}/like`, {
    method: 'DELETE'
  })

  return res
}

export const likeSpotV2 = async (
  fetcher: Fetch,
  spotId: string
): Promise<IRespV2> => {
  const res = await fetcher(API_V2.CONTENT + `/spot/${spotId}/like`, {
    method: 'PUT'
  })

  return res
}

export const unlikeSpotV2 = async (
  fetcher: Fetch,
  spotId: string
): Promise<IRespV2> => {
  const res = await fetcher(API_V2.CONTENT + `/spot/${spotId}/like`, {
    method: 'DELETE'
  })

  return res
}
